import React from "react";
import feel_the_flow from "../../img/feel_the_flow.webp";
import enhances_user from "../../img/enhances_user.webp";

function Home3() {
  return (
    <div className="home3">
      <p className="header-home3 margin-top-content">How we do?</p>
      <div className="home3-detail">
        <div className="content-detail">
          <div className="text-home3-1 padding-left-first-div">
            <p className="header-home3-1 color-1149E6">Feel the Flow</p>
            <div className="home3-1-detial">
            <p className="font-size-20">กระบวนการที่จะเกิดขึ้นของการร่วมกิจกรรมส่งเสริมสุขภาพได้แก่</p>
            <ul>
                <li>กิจกรรมได้ถูกสร้าง และประกาศจาก Server โดยที่ระบุ ตัวแปรของ Input ที่ต้องการสำหรับกิจกรรมต่างๆกัน ไม่ว่าจะเป็น
                    <ul>
                    <li>จำนวนก้าวเดินเป้าหมาย หรืระยะทางเป้าหมาย</li>
                    <li>ระยะเวลาของกิจกรรมนั้นๆ, วันที่เริ่มต้น, วันที่สิ้นสุด</li>
                    <li>การเปิดให้ User ลงทะเบียนเพื่อส่ง Input เข้ามา</li>
                    </ul>
                </li>
                <li>กิจกรรมต่างๆนั้นได้ถูก launch ออกมา</li>
                <li>User เลือกที่จะลงทะเบียนกิจกรรมที่ต้องการ</li>
                <li>User จะได้รับเงื่อนไข ให้ส่งตัวแปรที่กำหนดไว้ในกิจกรรมนั้นๆ</li>
                <li>User ส่งตัวแปรนั้น เพื่อร่วมกิจกรรม</li>
                <li>User จะเห็นการเปลี่ยนแปลง และ พัฒนาการในกิจกรรมที่เข้าร่วม</li>
            </ul>
            </div>
          </div>
          <div className="image-section middle">
            <img
              src={feel_the_flow}
              alt="Tracking"
              className="image image-margin-top"
            />
          </div>
        </div>
        <div className="content-detail convert-div margin-bottom-content">
          <div className="image-home3-2 padding-left-first-div">
            <img
              src={enhances_user}
              alt="Tracking"
              className="image image-margin-top"
            />
          </div>
          <div className="text-home3-2">
            <p className="header-home3-2 color-1149E6">Enhances User Functionality with Third-party API</p>
            <p className="font-size-20">การที่มีส่วน Automate การเชื่อมต่อกับ API นั้นเข้ามา Enhance Functionality ในแง่ของ</p>
            <ul>
              <li>{`การลดขั้นตอนของการกรอกข้อมูล User เช่น การสร้าง Entry > การ manually input data ด้วย Text หรือ Number`}</li>
              <li>การลดความถี่การกรอกข้อมูลของ User โดยเฉพาะในกรณีที่มี User ต้องการส่ง Input นั้นๆ ในหลายๆ Entry</li>
              <li>Enhance Validation ของข้อมูล Input ที่ user ได้ทำการกรอกเข้ามาในระบบ เนื่องจาก
                <ul>
                  <li>การกรอกแบบ Manual นั้น ไม่สามารถคัดกรองความถูกต้อง และ Validate ข้อมูลนั้นๆ ว่า ได้เกิดขึ้นจริงหรือไม่ ซึ่งส่งผลต่อการประมวลผล Progress และ ประสบการณ์ในการร่วมกิจกรรม</li>
                  <li>แต่การรับ Input จาก API นั้น สามารถ Validate ข้อมูลที่ต้องการได้จริง ว่าเกิดขึ้นจากกระบวนการต่างๆ เช่น จาก Pedometer, GPS และ อื่นๆ</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home3;
